export const MetalColorMenuRoseGold: React.FC<React.SVGAttributes<{}>> = (
  props,
) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="RG">
      <rect
        id="Rectangle 1"
        width="20"
        height="20"
        rx="10"
        fill="url(#paint0_linear_1923_11554)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1923_11554"
        x1="18.7439"
        y1="20"
        x2="1.2561"
        y2="1.06265e-07"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F4B39C" />
        <stop offset="0.45" stopColor="#FFF5F2" />
        <stop offset="0.5" stopColor="#FFF7F5" />
        <stop offset="0.55" stopColor="#FFF5F2" />
        <stop offset="1" stopColor="#F4B39C" />
      </linearGradient>
    </defs>
  </svg>
);
