const ReturnIcon = ({ color = 'currentColor', className = '' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="return">
        <path
          id="Vector"
          d="M16.6927 14.1925L14.1927 16.6925C14.0754 16.8098 13.9163 16.8757 13.7505 16.8757C13.5846 16.8757 13.4256 16.8098 13.3083 16.6925C13.191 16.5753 13.1251 16.4162 13.1251 16.2503C13.1251 16.0845 13.191 15.9254 13.3083 15.8082L14.7419 14.3753H3.75049C3.58473 14.3753 3.42576 14.3095 3.30855 14.1923C3.19134 14.0751 3.12549 13.9161 3.12549 13.7503C3.12549 13.5846 3.19134 13.4256 3.30855 13.3084C3.42576 13.1912 3.58473 13.1253 3.75049 13.1253H14.7419L13.3083 11.6925C13.191 11.5753 13.1251 11.4162 13.1251 11.2503C13.1251 11.0845 13.191 10.9254 13.3083 10.8082C13.4256 10.6909 13.5846 10.625 13.7505 10.625C13.9163 10.625 14.0754 10.6909 14.1927 10.8082L16.6927 13.3082C16.7508 13.3662 16.7969 13.4351 16.8283 13.511C16.8598 13.5869 16.876 13.6682 16.876 13.7503C16.876 13.8325 16.8598 13.9138 16.8283 13.9897C16.7969 14.0656 16.7508 14.1345 16.6927 14.1925ZM5.8083 9.19254C5.92558 9.30981 6.08464 9.3757 6.25049 9.3757C6.41634 9.37569 6.5754 9.30981 6.69268 9.19254C6.80995 9.07526 6.87584 8.9162 6.87584 8.75035C6.87584 8.5845 6.80995 8.42544 6.69268 8.30816L5.25909 6.87535H16.2505C16.4163 6.87535 16.5752 6.8095 16.6924 6.69229C16.8096 6.57508 16.8755 6.41611 16.8755 6.25035C16.8755 6.08459 16.8096 5.92562 16.6924 5.80841C16.5752 5.6912 16.4163 5.62535 16.2505 5.62535H5.25909L6.69268 4.19253C6.80995 4.07526 6.87584 3.9162 6.87584 3.75035C6.87584 3.5845 6.80995 3.42544 6.69268 3.30816C6.5754 3.19088 6.41634 3.125 6.25049 3.125C6.08464 3.125 5.92558 3.19088 5.8083 3.30816L3.3083 5.80816C3.25019 5.86621 3.20409 5.93514 3.17264 6.01101C3.14119 6.08688 3.125 6.16821 3.125 6.25035C3.125 6.33248 3.14119 6.41381 3.17264 6.48969C3.20409 6.56556 3.25019 6.63449 3.3083 6.69254L5.8083 9.19254Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default ReturnIcon;
