export const MetalColor14KWhiteRoseGold: React.FC<React.SVGAttributes<{}>> = (
  props,
) => (
  <svg
    width="46"
    height="46"
    {...props}
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="46" height="46" rx="5" fill="white" />
    <rect
      x="5"
      y="5"
      width="36"
      height="36"
      rx="3"
      fill="url(#paint0_linear_1071_2694)"
    />
    <path
      d="M15.1335 17.8182V28H13.9006V19.1108H13.8409L11.3551 20.7614V19.5085L13.9006 17.8182H15.1335ZM17.8232 25.9119V24.8977L22.2976 17.8182H23.0334V19.3892H22.5362L19.1555 24.7386V24.8182H25.1811V25.9119H17.8232ZM22.6158 28V25.6037V25.1314V17.8182H23.7891V28H22.6158ZM27.2232 28V17.8182H28.4561V22.8693H28.5755L33.1493 17.8182H34.7601L30.4846 22.4119L34.7601 28H33.2686L29.7289 23.267L28.4561 24.6989V28H27.2232Z"
      fill="black"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1071_2694"
        x1="10.087"
        y1="35.913"
        x2="36.6957"
        y2="9.69565"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D0CFCE" />
        <stop offset="0.23" stopColor="#F6F6F6" />
        <stop offset="0.525" stopColor="white" />
        <stop offset="0.78" stopColor="#FFEDE7" />
        <stop offset="1" stopColor="#F4BC9C" />
      </linearGradient>
    </defs>
  </svg>
);
