export const MetalColorMenuPlatinum: React.FC<React.SVGAttributes<{}>> = (
  props,
) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="PT">
      <rect
        id="Rectangle 1"
        width="20"
        height="20"
        rx="10"
        fill="url(#paint0_linear_1923_11558)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1923_11558"
        x1="-1.04773e-07"
        y1="0.443606"
        x2="20"
        y2="19.5564"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B0B0B0" />
        <stop offset="0.45" stopColor="#F2F2F2" />
        <stop offset="0.5" stopColor="#F5F5F5" />
        <stop offset="0.55" stopColor="#F2F2F2" />
        <stop offset="1" stopColor="#B0B0B0" />
      </linearGradient>
    </defs>
  </svg>
);
