export const MetalColor14KWhiteGold: React.FC<React.SVGAttributes<{}>> = (
  props,
) => (
  <svg
    width="46"
    height="46"
    {...props}
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="46" height="46" rx="5" fill="white" />
    <rect
      x="5"
      y="5"
      width="36"
      height="36"
      rx="3"
      fill="url(#paint0_linear_960_5659)"
    />
    <path
      d="M15.6335 17.8182V28H14.4006V19.1108H14.3409L11.8551 20.7614V19.5085L14.4006 17.8182H15.6335ZM18.3232 25.9119V24.8977L22.7976 17.8182H23.5334V19.3892H23.0362L19.6555 24.7386V24.8182H25.6811V25.9119H18.3232ZM23.1158 28V25.6037V25.1314V17.8182H24.2891V28H23.1158ZM28.6578 25.2159L28.638 23.7642H28.8766L32.2175 20.3636H33.6692L30.1096 23.9631H30.0101L28.6578 25.2159ZM27.5641 28V17.8182H28.7374V28H27.5641ZM32.4164 28L29.4334 24.2216L30.2686 23.4062L33.9078 28H32.4164Z"
      fill="black"
    />
    <defs>
      <linearGradient
        id="paint0_linear_960_5659"
        x1="10.087"
        y1="35.913"
        x2="36.6957"
        y2="9.69565"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D0CFCE" />
        <stop offset="0.23" stopColor="#F6F6F6" />
        <stop offset="0.525" stopColor="white" />
        <stop offset="0.78" stopColor="#F7F7F7" />
        <stop offset="1" stopColor="#D0CFCE" />
      </linearGradient>
    </defs>
  </svg>
);
