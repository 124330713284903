export const MetalColorMenuWhiteGold: React.FC<React.SVGAttributes<{}>> = (
  props,
) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="WG">
      <rect
        id="Rectangle 1"
        width="20"
        height="20"
        rx="10"
        fill="url(#paint0_linear_1923_11546)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1923_11546"
        x1="0.115911"
        y1="5.66186e-07"
        x2="19.8841"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D0CFCE" />
        <stop offset="0.45" stopColor="#F6F6F6" />
        <stop offset="0.5" stopColor="#F7F7F7" />
        <stop offset="0.55" stopColor="#F6F6F6" />
        <stop offset="1" stopColor="#D0CFCE" />
      </linearGradient>
    </defs>
  </svg>
);
