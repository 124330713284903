export const MetalColorMenuYellowGold: React.FC<React.SVGAttributes<{}>> = (
  props,
) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="YG">
      <rect
        id="Rectangle 1"
        width="20"
        height="20"
        rx="10"
        fill="url(#paint0_linear_1923_11550)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1923_11550"
        x1="2.71494e-07"
        y1="0.171347"
        x2="20"
        y2="19.8287"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFDCA5" />
        <stop offset="0.45" stopColor="#FFFBF2" />
        <stop offset="0.5" stopColor="#FFFCF5" />
        <stop offset="0.55" stopColor="#FFFBF2" />
        <stop offset="1" stopColor="#EFDCA5" />
      </linearGradient>
    </defs>
  </svg>
);
