export const MetalColorPT950: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg
    width="46"
    height="46"
    {...props}
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="46" height="46" rx="5" fill="white" />
    <rect
      x="5"
      y="5"
      width="36"
      height="36"
      rx="3"
      fill="url(#paint0_linear_960_5677)"
    />
    <path
      d="M15.233 28V17.8182H18.6733C19.4721 17.8182 20.125 17.9624 20.6321 18.2507C21.1425 18.5357 21.5204 18.9219 21.7656 19.4091C22.0109 19.8963 22.1335 20.4399 22.1335 21.0398C22.1335 21.6397 22.0109 22.1849 21.7656 22.6754C21.5237 23.166 21.1491 23.5571 20.642 23.8487C20.1349 24.1371 19.4853 24.2812 18.6932 24.2812H16.2273V23.1875H18.6534C19.2003 23.1875 19.6394 23.093 19.9709 22.9041C20.3023 22.7152 20.5426 22.46 20.6918 22.1385C20.8442 21.8137 20.9205 21.4474 20.9205 21.0398C20.9205 20.6321 20.8442 20.2675 20.6918 19.946C20.5426 19.6245 20.3007 19.3726 19.9659 19.1903C19.6312 19.0047 19.187 18.9119 18.6335 18.9119H16.4659V28H15.233ZM23.5629 18.9119V17.8182H31.1992V18.9119H27.9975V28H26.7646V18.9119H23.5629Z"
      fill="black"
    />
    <defs>
      <linearGradient
        id="paint0_linear_960_5677"
        x1="10.087"
        y1="35.913"
        x2="36.6957"
        y2="9.69565"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B0B0B0" />
        <stop offset="0.23" stopColor="#F6F6F6" />
        <stop offset="0.525" stopColor="white" />
        <stop offset="0.78" stopColor="#F7F7F7" />
        <stop offset="1" stopColor="#B0B0B0" />
      </linearGradient>
    </defs>
  </svg>
);
