const CustomIcon = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M16.25 2.49952H12.5C12.1685 2.49952 11.8505 2.63121 11.6161 2.86563C11.3817 3.10005 11.25 3.41799 11.25 3.74952V16.2495C11.25 16.581 11.3817 16.899 11.6161 17.1334C11.8505 17.3678 12.1685 17.4995 12.5 17.4995H16.25C16.5815 17.4995 16.8995 17.3678 17.1339 17.1334C17.3683 16.899 17.5 16.581 17.5 16.2495V3.74952C17.5 3.41799 17.3683 3.10005 17.1339 2.86563C16.8995 2.63121 16.5815 2.49952 16.25 2.49952ZM16.25 16.2495H12.5V13.7495H14.375C14.5408 13.7495 14.6997 13.6837 14.8169 13.5665C14.9342 13.4492 15 13.2903 15 13.1245C15 12.9588 14.9342 12.7998 14.8169 12.6826C14.6997 12.5654 14.5408 12.4995 14.375 12.4995H12.5V10.6245H14.375C14.5408 10.6245 14.6997 10.5587 14.8169 10.4415C14.9342 10.3242 15 10.1653 15 9.99952C15 9.83376 14.9342 9.67478 14.8169 9.55757C14.6997 9.44036 14.5408 9.37452 14.375 9.37452H12.5V7.49952H14.375C14.5408 7.49952 14.6997 7.43367 14.8169 7.31646C14.9342 7.19925 15 7.04028 15 6.87452C15 6.70876 14.9342 6.54978 14.8169 6.43257C14.6997 6.31536 14.5408 6.24952 14.375 6.24952H12.5V3.74952H16.25V16.2495ZM6.06719 2.05733C6.00914 1.99922 5.94021 1.95312 5.86434 1.92167C5.78846 1.89021 5.70713 1.87402 5.625 1.87402C5.54287 1.87402 5.46154 1.89021 5.38566 1.92167C5.30979 1.95312 5.24086 1.99922 5.18281 2.05733L2.68281 4.55733C2.62479 4.61541 2.57878 4.68436 2.54741 4.76023C2.51605 4.83611 2.49994 4.91742 2.5 4.99952V16.2495C2.5 16.581 2.6317 16.899 2.86612 17.1334C3.10054 17.3678 3.41848 17.4995 3.75 17.4995H7.5C7.83152 17.4995 8.14946 17.3678 8.38388 17.1334C8.6183 16.899 8.75 16.581 8.75 16.2495V4.99952C8.75006 4.91742 8.73395 4.83611 8.70259 4.76023C8.67122 4.68436 8.62521 4.61541 8.56719 4.55733L6.06719 2.05733ZM3.75 13.7495V6.24952H5V13.7495H3.75ZM6.25 6.24952H7.5V13.7495H6.25V6.24952ZM5.625 3.38311L7.24141 4.99952H4.00859L5.625 3.38311ZM3.75 16.2495V14.9995H7.5V16.2495H3.75Z"
        fill="#474555"
      />
    </svg>
  );
};

export default CustomIcon;
